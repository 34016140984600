import React from 'react'
import { Switch, Route } from 'react-router-dom'

import GridView from './GridContainer'
import DocumentList from './DocumentListContainer'

const DocumentPage = ({ match, title }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={DocumentList} />
      <Route path={`${match.path}/`} component={GridView} />
    </Switch>
  )
}

export default DocumentPage
