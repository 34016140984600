import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'redux-form'

import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = theme => ({
  root: {
    width: '90%'
  },
  button: {
    marginRight: theme.spacing.unit
  },
  completed: {
    display: 'inline-block'
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
})

function HorizontalNonLinearStepper({
  classes,
  steps,
  children,
  pristine,
  submitting,
  handleSubmit,
  onCancel,
  onDelete
}) {
  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})

  function totalSteps() {
    return steps.length
  }

  function completedSteps() {
    return Object.keys(completed).length
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps()
  }

  function handleNext() {
    let newActiveStep

    if (isLastStep() && !allStepsCompleted()) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      newActiveStep = steps.findIndex((step, i) => !(i in completed))
    } else {
      newActiveStep = activeStep + 1
    }
    setActiveStep(newActiveStep)
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  function handleComplete() {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  function handleReset() {
    setActiveStep(0)
    setCompleted({})
  }

  return (
    <React.Fragment>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={handleStep(index)}
              completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <Card>
            <CardContent>{children[activeStep]}</CardContent>
            <CardActions className={classes.actions}>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}>
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}>
                  Próximo
                </Button>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? 'Finalizar'
                        : 'Completar Etapa'}
                    </Button>
                  ))}
              </div>
              <div>
                <Button
                  color="default"
                  className={classes.button}
                  onClick={onCancel}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  onClick={onDelete}
                  disabled={submitting}>
                  <DeleteIcon className={classes.leftIcon} fontSize="small" />
                  Apagar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={pristine || submitting}>
                  <SaveIcon className={classes.leftIcon} fontSize="small" />
                  Salvar
                </Button>
              </div>
            </CardActions>
          </Card>
        )}
      </form>
    </React.Fragment>
  )
}

HorizontalNonLinearStepper.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(HorizontalNonLinearStepper)
