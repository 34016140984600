import React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { gql } from 'apollo-boost'
import Grid from '@material-ui/core/Grid'

import { reduxForm } from 'redux-form'
import {
  Form,
  TextField,
  SelectField,
  PhoneList,
  AddressList
} from '@ftrustee/ui'

const FormTemplate = ({ options, onCnpjBlur, ...props }) => {
  return (
    <Form {...props}>
      <TextField
        required
        name="cnpj"
        label="CNPJ"
        type="cnpj"
        onBlur={onCnpjBlur}
        xs={12}
        sm={3}
      />
      <TextField
        required
        disabled={props.loading}
        placeholder={'Carregando...'}
        name="name"
        label="Razão Social"
        xs={12}
        sm={7}
      />
      <TextField
        required
        upperCase
        name="cod"
        label="Código"
        sm={2}
        inputProps={{
          maxLength: 4
        }}
      />
      <TextField name="businessName" label="Nome Fantasia" xs={12} sm={6} />
      <TextField name="group" label="Grupo" xs={12} sm={6} />
      <TextField xs name="website" label="Website" />
      <TextField xs name="relationship" label="Relação" />
      <TextField xs name="industry" label="Indústria" />

      <SelectField
        xs
        name="status"
        label="Situação"
        options={['Ativa', 'Inativa', 'Em Recuperação Judicial']}
      />
      <SelectField
        xs
        name="held"
        label="Propriedade"
        options={['Privada', 'Pública', 'Mista']}
      />
      <SelectField
        xs
        name="capital"
        label="Capital"
        options={['Aberto', 'Fechado']}
      />

      <TextField name="notes" label="Notas" multiline rowsMax="4" xs={12} />
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <PhoneList />
        </Grid>
      </Grid>
      <AddressList />
    </Form>
  )
}

FormTemplate.propTypes = {
  options: PropTypes.object.isRequired
}

FormTemplate.defaultProps = {
  options: {}
}

const FormView = compose(
  reduxForm({ form: 'company' }),
  withApollo,
  connect(),
  withHandlers({
    onCnpjBlur: ({
      client,
      initialized,
      autofill,
      isLoading,
      dispatch
    }) => async (event, newValue, previousValue, name) => {
      const cnpj = newValue.replace(/\D/g, '')
      if (initialized || !cnpj) return
      dispatch({
        type: 'APP_LOADING',
        payload: true
      })
      const { data } = await client.query({
        query: gql`
          query find($cnpj: String!) {
            findCompany(cnpj: $cnpj)
          }
        `,
        variables: { cnpj }
      })

      const company = data.findCompany
      for (var prop in data.findCompany) {
        if (company.hasOwnProperty(prop)) {
          console.log('FormView#onCnpjBlur', prop, company[prop])
          autofill(prop, company[prop])
        }
      }
      dispatch({
        type: 'APP_LOADING',
        payload: false
      })
    }
  })
)(FormTemplate)

export default FormView
