import { compose, branch, withHandlers, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { omit, omitBy, remove, isNull, pick } from 'lodash'

import FormView from './FormView'
import { QUERY } from './GridContainer'

const fields = gql`
  fragment fields on Security {
    id
    cod
    name
    type
    status
    notes
    issuer {
      id
    }
    manager {
      id
    }
    keeper {
      id
    }
    owner {
      id
    }
  }
`

const withData = graphql(
  gql`
    query security($id: ID!) {
      security(id: $id) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    options: ({ match }) => ({
      variables: {
        id: match.params.id
      }
    }),
    props: ({ data }) => {
      const props = {
        loading: data.loading
      }
      if (!data.loading) {
        const initialValues = omit(omitBy(data.security, isNull), '__typename')

        initialValues.issueNumber = parseInt(initialValues.cod.substr(7, 3))
        initialValues.seriesStart = parseInt(initialValues.cod.substr(10, 4))
        initialValues.seriesEnd = parseInt(initialValues.cod.substr(14, 4))
        props.initialValues = initialValues
      }
      return props
    }
  }
)

const withSave = graphql(
  gql`
    mutation createSecurity($data: SecurityInput!) {
      createSecurity(data: $data) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: values => {
        console.log(values)
        return false
        // const data = pick(
        //   values,
        //   'cod',
        //   'name',
        //   'type',
        //   'status',
        //   'notes',
        //   'issuer.id',
        //   'manager.id',
        //   'keeper.id',
        //   'owner.id'
        // )

        // return mutate({ variables: { data } }).then(() =>
        //   history.push('/securities')
        // )
      }
    }),
    options: () => ({
      update: (cache, { data: { createSecurity } }) => {
        const { securities } = cache.readQuery({ query: QUERY })
        cache.writeQuery({
          query: QUERY,
          data: { securities: securities.concat([createSecurity]) }
        })
      }
    })
  }
)

const withUpade = graphql(
  gql`
    mutation updateSecurity($id: ID!, $data: SecurityInput!) {
      updateSecurity(id: $id, data: $data) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: values => {
        console.log(values)
        const { id } = values
        const data = pick(
          values,
          'cod',
          'name',
          'type',
          'status',
          'notes',
          'issuer.id',
          'manager.id',
          'keeper.id',
          'owner.id'
        )
        return mutate({ variables: { id, data } }).then(() =>
          history.push('/securities')
        )
      }
    })
  }
)

const withDelete = graphql(
  gql`
    mutation deleteSecurity($id: ID!) {
      deleteSecurity(id: $id) {
        id
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { match, history } }) => ({
      onDelete: () =>
        mutate({ variables: { id: match.params.id } }).then(() =>
          history.replace('/securities')
        )
    }),
    options: ({ match }) => ({
      update: (proxy, { data: { deleteSecurity } }) => {
        const data = proxy.readQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          }
        })
        remove(data.securities, deleteSecurity)
        proxy.writeQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          },
          data
        })
      }
    })
  }
)

const withCompanies = graphql(
  gql`
    query {
      companies {
        id
        name
        cod
      }
    }
  `,
  {
    props: ({ data }) =>
      data.loading
        ? { companies: [] }
        : {
            companies: data.companies.map(company => ({
              value: company.id,
              label: company.name,
              cod: company.cod
            }))
          }
  }
)

export default compose(
  withCompanies,
  withProps(({ companies }) => ({
    options: {
      types,
      status,
      collateral,
      services,
      marketplaces,
      companies
    }
  })),
  branch(
    ({ match }) => !!match.params.id,
    compose(
      withData,
      withUpade,
      withDelete
    ),
    withSave
  ),
  withHandlers({
    onCancel: ({ history }) => () => history.push('/securities')
  })
)(FormView)

const collateral = {
  DEB: [
    { value: 'DEB:REAL', label: 'Real' },
    { value: 'DEB:QUIROGRAFARIA', label: 'Quirografária' },
    { value: 'DEB:FIDEJUSSORIA', label: 'Fidejussória' },
    { value: 'DEB:SUBORDINADA', label: 'Subordinada' },
    { value: 'DEB:FLUTUANTE', label: 'Flutuante' }
  ],
  NPC: [
    { value: 'NPC:AVAL', label: 'Aval' },
    { value: 'NPC:SEM_GARANTIA', label: 'Sem garantia' },
    { value: 'NPC:REAL', label: 'Real' },
    { value: 'NPC:CORPORATIVA', label: 'Corporativa' }
  ]
}

const services = [
  { value: 'AF', label: 'AF - AGENTE FIDUCIÁRIO' },
  { value: 'AC', label: 'AC - AGENTE CUSTODIANTE' },
  { value: 'AG', label: 'AG - AGENTE DE GARANTIA' },
  { value: 'IP', label: 'IP - IMPLANTAÇÃO' },
  { value: 'IR', label: 'IR - IMPLANTAÇÃO E REGISTRO' },
  { value: 'RG', label: 'RG - INSTITUIÇÃO REGISTRADORA' },
  { value: 'AN', label: 'AN - AGENTE DE NOTAS PROMISSÓRIAS' },
  { value: 'AL', label: 'AL - AGENTE DE LETRAS FINANCEIRAS' },
  { value: 'AAC', label: 'AAC - AGENTE DE ACOMPANHAMENTO DE COVENANTS' }
]

const marketplaces = [
  { value: 'DIVIDA', label: 'DÍVIDA' },
  { value: 'IMOBILIARIO', label: 'IMOBILIÁRIO' },
  { value: 'AGRONEGOCIO', label: 'AGRONEGÓCIO' }
]

const types = [
  { value: 'CCB', label: 'CCB' },
  { value: 'CCE', label: 'CCE' },
  { value: 'CCI', label: 'CCI' },
  { value: 'CDC', label: 'CDC' },
  { value: 'CPR', label: 'CPR' },
  { value: 'CRA', label: 'CRA' },
  { value: 'CRI', label: 'CRI' },
  { value: 'DEB', label: 'DEB' },
  { value: 'LCI', label: 'LCI' },
  { value: 'LFB', label: 'LFB' },
  { value: 'LFS', label: 'LFS' },
  { value: 'NCE', label: 'NCE' },
  { value: 'NPC', label: 'NPC' },
  { value: 'OPI', label: 'OPI' }
]

const status = [
  { value: 'default', label: 'Default' },
  { value: 'active', label: 'Ativa' },
  { value: 'deleted', label: 'Excluída' }
]
