import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { GridView } from '@ftrustee/ui'

export const QUERY = gql`
  query {
    companies {
      id
      cod
      name
      cnpj
      phones
    }
  }
`
const withData = graphql(QUERY)

export default compose(
  withData,
  withProps(({ data }) => ({
    columns: [
      { name: 'cnpj', title: 'CNPJ' },
      { name: 'cod', title: 'CÓDIGO' },
      { name: 'name', title: 'NOME', sort: 'asc' },
      {
        name: 'phones',
        title: 'TELEFONE',
        getCellValue: row => row.phones && row.phones[0].value
      },
      { name: 'id', title: ' ' }
    ],
    extensions: [
      { columnName: 'cnpj', width: 200, align: 'center' },
      { columnName: 'cod', width: 100, align: 'center' },
      { columnName: 'phones', width: 200, align: 'center' },
      { columnName: 'id', width: 75, align: 'right' }
    ],
    loading: data.loading,
    rows: data.companies
  }))
)(GridView)
