import React from 'react'
import { FormSection, Field } from 'redux-form'
import { compose, pure } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import firebase from 'firebase/app'
import 'firebase/storage'

import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CustomField from './CustomField'
import { AutocompleteField, FileInput, CheckboxField } from '@ftrustee/ui'

const renderField = ({ input }) => {
  console.log(input)

  return (
    <a
      onClick={() =>
        firebase
          .storage()
          .ref(input.value.path)
          .getDownloadURL()
          .then(function(url) {
            // `url` is the download URL for document
            // This can be downloaded directly:
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = function(event) {
              const blob = xhr.response
              const a = document.createElement('a')
              a.href = window.URL.createObjectURL(blob)
              a.download = input.value.name
              a.click()
            }
            xhr.open('GET', url)
            xhr.send()
          })
      }
      href="javascript:void(0);">
      {input.value.name}
    </a>
  )
}

const RegisterItem = ({
  classes,
  loading,
  name,
  options,
  file,
  children,
  ...props
}) => {
  console.log(props, file)

  return (
    <Grid container spacing={16}>
      <AutocompleteField
        sm
        name={`${name}.name`}
        label="Registro"
        options={options}
      />
      <Grid item sm={4}>
        <FileInput name={`${name}.file`} />
        <FormControlLabel
          control={
            <CheckboxField
              name={`${name}.done`}
              classes={{
                root: classes.root,
                checked: classes.checked
              }}
              onChange={(event, newValue, previousValue, name) => {
                !previousValue &&
                  !window.confirm('Confirma a finalização da pendência?') &&
                  event.preventDefault()
              }}
            />
          }
          label="PENDÊNCIA FINALIZADA"
        />
      </Grid>
      <Grid item sm={12}>
        <Field name={`${name}.file`} component={renderField} />
      </Grid>

      <Grid item sm={12}>
        <FormSection
          container
          spacing={16}
          component={Grid}
          name={`${name}.tracking`}>
          <CustomField sm={4} name="pdf" label="PDF" />
          <CustomField sm={4} name="copy" label="CÓPIA AUTENTICADA" />
          <CustomField sm={4} name="original" label="VIA ORIGINAL" />
        </FormSection>
      </Grid>
      <Grid item sm={12}>
        {children}
      </Grid>
    </Grid>
  )
}

const styles = {
  root: {
    color: green[600],
    '&$checked': {
      color: green[500]
    }
  },
  checked: {}
}

export default compose(
  withStyles(styles),
  pure
)(RegisterItem)
