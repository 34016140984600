import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter } from 'lodash'

import DocumentList from './DocumentList'

export const QUERY = gql`
  query documents($id: ID!) {
    documents(where: { security: { id: $id } }) {
      id
      title
      reference
      inventory
      issueDate
      notes
      tracking
      registers
    }
  }
`

const withSecurity = graphql(
  gql`
    query security($id: ID!) {
      security(id: $id) {
        id
        name
        type
      }
    }
  `,
  {
    options: ({ match }) => ({
      variables: {
        id: match.params.id
      }
    }),
    props: ({ data }) => {
      if (!data.loading) {
        const { security } = data
        return { security, tabs: tabs[security.type] }
      }
    }
  }
)

const withData = graphql(QUERY, {
  options: ({ match }) => ({
    variables: {
      id: match.params.id
    }
  }),
  props: ({ data, ownProps: { location } }) => {
    const props = {
      loading: data.loading,
      currentTab: location.hash.replace('#', '') || 'ata',
      columns: [
        { name: 'inventory', title: 'Inventário' },
        { name: 'title', title: 'Título' },
        { name: 'issueDate', title: 'Data do Documento' },
        {
          name: 'pending',
          title: 'Pendências',
          getCellValue: row => {
            if (!row.registers) return 0
            const total = row.registers.length
            const done = filter(row.registers, { done: true }).length
            return total - done
          }
        },
        { name: 'id', title: ' ' }
      ]
    }
    if (!data.loading) {
      // const { documents, ...security } = data.documents
      props.rows = filter(data.documents, { reference: props.currentTab })
    }
    return props
  }
})

export default compose(
  withData,
  withSecurity
)(DocumentList)

const tabs = {
  DEB: ['ATA', 'Produto', 'Garantias', 'Importante'],
  CRI: ['ATA', 'Produto', 'Garantias', 'Importante'],
  CRA: ['ATA', 'Produto', 'Garantias', 'Importante'],
  NPC: ['ATA', 'Produto', 'Garantias', 'Importante'],
  LFS: ['ATA', 'Produto', 'Garantias', 'Importante']
}
