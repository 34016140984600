import React from 'react'
import { FieldArray } from 'redux-form'
import { pure, onlyUpdateForKeys } from 'recompose'
import Button from '@material-ui/core/Button'

import RegisterItem from './RegisterItem'

const renderMembers = onlyUpdateForKeys(['options', 'fields'])(
  ({ fields, options, ...props }) => {
    // console.log('renderMembers:', fields, props)
    // fields.length === 0 && fields.push()
    return (
      <React.Fragment>
        <RegisterItem name={`${fields.name}[0]`} options={options}>
          <Button
            fullWidth
            variant="outlined"
            size="small"
            color="primary"
            aria-label="adicionar"
            onClick={() => fields.push()}>
            Adicionar Registro
          </Button>
        </RegisterItem>
        {fields.map(
          (member, index, fields) =>
            index > 0 && (
              <React.Fragment key={index}>
                <RegisterItem key={index} name={member} options={options}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    aria-label="adicionar"
                    onClick={() => fields.remove(index)}>
                    Remover Registro
                  </Button>
                </RegisterItem>
              </React.Fragment>
            )
        )}
      </React.Fragment>
    )
  }
)

const RegisterList = props => {
  // console.log('RegisterList:', props)
  return <FieldArray {...props} component={renderMembers} />
}

export default pure(RegisterList)
