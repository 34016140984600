import React, { useContext, useState, useEffect } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Page, FirebaseContext, SigninPage } from '@ftrustee/ui'

import Home from './components/Home'
import CompanyPage from './components/Company'
import SecurityPage from './components/Security'
import DocumentPage from './components/Document'

import { StackdriverErrorReporter } from 'stackdriver-errors-js'

window.StackTrace = require('stacktrace-js')
const errorHandler = new StackdriverErrorReporter()
errorHandler.start({
  key: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  disabled: process.env.NODE_ENV === 'development' // (optional) Set to true to not report errors when calling report(), this can be used when developping locally.
  // service: '<my-service>', // (optional)
  // version: '<my-service-version>' // (optional)
  // reportUncaughtExceptions: false          // (optional) Set to false to stop reporting unhandled exceptions.
  // reportUnhandledPromiseRejections: false  // (optional) Set to false to stop reporting unhandled promise rejections.
  // context: {user: 'user1'}                 // (optional) You can set the user later using setUser()
})

const history = createHistory()
function gtag() {
  window.dataLayer.push(arguments)
}

const send = location => {
  const parameters = {
    page_path: `${location.pathname}${location.search}${location.hash}`
  }
  if (window.uid) {
    parameters.user_id = window.uid
  }
  gtag('config', 'UA-121450028-1', parameters)
}

send(history.location)
history.listen(location => {
  send(location)
})

const menu = [
  { ico: 'location_city', label: 'Empresas', to: '/companies' },
  { ico: 'monetization_on', label: 'Operações', to: '/securities' }
]

const Main = ({ loading, sidebar, handleDrawer, isLoading }) => {
  console.log('#Main:render')
  const [logged, setLogged] = useState(false)
  console.log('#logged:', logged)

  const firebase = useContext(FirebaseContext)
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      isLoading(false)
      if (user && !logged) {
        console.log('#onAuthStateChanged', user.toJSON())
        !logged &&
          user.getIdToken().then(token => {
            console.log(token)
            errorHandler.setUser(user.uid)
            window.token = token
            window.uid = user.uid
            setLogged(true)
          })
      }
      if (!user && logged) {
        // User is signed out.
        window.token = null
        window.uid = null
        setLogged(false)
      }
    })
  }, [logged])

  return (
    <Router history={history}>
      {logged ? (
        <Page
          title="securities"
          menu={menu}
          open={sidebar}
          loading={loading}
          handleDrawer={handleDrawer}>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/companies" component={CompanyPage} />
            <Route path="/securities" component={SecurityPage} />
            <Route path="/documents" component={DocumentPage} />
          </Switch>
        </Page>
      ) : (
        <Route path="/" component={SigninPage} />
      )}
    </Router>
  )
}

export default compose(
  connect(
    state => ({
      loading: state.app.loading,
      sidebar: state.app.sidebar
    }),
    dispatch => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        }),
      handleDrawer: opened =>
        dispatch({
          type: 'APP_SIDEBAR',
          payload: opened
        })
    })
  )
)(Main)
