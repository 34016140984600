import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { filter } from 'lodash'

import { GridView } from '@ftrustee/ui'

export default compose(
  graphql(gql`
    query securities {
      securities {
        id
        name
        issuer {
          id
          name
        }
      }
    }
  `),
  graphql(
    gql`
      query documents {
        documents {
          id
          registers
          security {
            id
          }
        }
      }
    `,
    { name: 'withDocuments' }
  ),
  withProps(({ data, withDocuments }) => {
    console.log(data, withDocuments)
    return {
      columns: [
        { name: 'name', title: 'OPERAÇÃO' },
        {
          name: 'issuer',
          title: 'EMISSOR',
          sort: 'asc',
          getCellValue: row => row.issuer && row.issuer.name
        },
        {
          name: 'documents',
          title: 'Documentos',
          getCellValue: row => {
            const documents = filter(withDocuments.documents, [
              'security.id',
              row.id
            ])
            return documents && documents.length
          }
        },
        {
          name: 'pending',
          title: 'Pendências',
          getCellValue: row => {
            const documents = filter(withDocuments.documents, [
              'security.id',
              row.id
            ])

            let pending = 0
            documents &&
              documents.forEach(element => {
                if (!element.registers) return 0
                const total = element.registers.length
                const done = filter(element.registers, { done: true }).length
                pending += total - done
              })
            return pending
          }
        },
        { name: 'id', title: ' ' }
      ],
      extensions: [
        { columnName: 'id', width: 75, align: 'right' },
        { columnName: 'documents', width: 120, align: 'center' },
        { columnName: 'pending', width: 120, align: 'center' }
      ],
      grouping: [{ columnName: 'issuer' }],
      loading: data.loading,
      rows: data.securities,
      nofab: true
    }
  })
)(GridView)
