import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'

import { connect } from 'react-redux'
import { Form, reduxForm, formValueSelector, FieldArray } from 'redux-form'

import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import {
  TextField,
  NumberField,
  DateField,
  SelectField,
  AutocompleteField,
  CheckboxField
} from '@ftrustee/ui'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import Stepper from './Stepper'

const FormTemplate = ({ type, options, handleSubmit, ...props }) => {
  console.log('render#FormTemplate', props)
  return (
    !props.loading && (
      <Stepper
        steps={['Cotação', 'Estruturação', 'Liquidação']}
        handleSubmit={handleSubmit}>
        <Grid container spacing={16}>
          <Grid item sm={8}>
            <Grid container spacing={16}>
              <AutocompleteField
                required
                isAsync
                name="issuer.id"
                label="Emissor"
                sm={9}
                options={options.companies}
              />
              <SelectField
                sm={3}
                name="marketplace"
                label="Mercado"
                options={options.marketplaces}
              />

              <SelectField
                required
                sm={3}
                name="type"
                label="Instrumento"
                options={options.types}
              />
              <AutocompleteField
                isMulti
                sm={9}
                name="collateral"
                label="Garantia"
                options={type ? options.collateral[type] : []}
              />

              <NumberField
                required
                sm={2}
                name="issueNumber"
                label="Número"
                helperText="emissão"
              />
              <NumberField sm={3} name="issue.value" label="Valor da Emissão" />
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <Grid container spacing={16}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Serviços</FormLabel>
                <FormGroup>
                  {options.services &&
                    options.services.map(({ label, value }, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={<CheckboxField name={`services[${value}]`} />}
                        label={label}
                      />
                    ))}
                </FormGroup>
                <FormHelperText>selecione todos os serviços</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <DateField sm={2} name={`issue.date`} label="Emissão" />
          <DateField sm={2} name={`issue.dueDate`} label="Vencimento" />
          <Grid item sm={6} />
          <AutocompleteField
            isAsync
            isClearable
            name="owner.id"
            label="Mandatário"
            sm={4}
            options={options.companies}
          />
          <AutocompleteField
            isAsync
            isClearable
            name="manager.id"
            label="Coordenador"
            sm={4}
            options={options.companies}
          />
          <AutocompleteField
            isAsync
            isClearable
            name="keeper.id"
            label="Escriturador"
            sm={4}
            options={options.companies}
          />
          <TextField
            multiline
            rowsMax="4"
            name="payoff"
            label="Remuneração"
            sm={12}
          />
        </Grid>
        <Grid container spacing={16}>
          <TextField
            required
            xs={12}
            sm={7}
            name="name"
            label="Nome da Operação"
          />
          <SelectField
            sm={2}
            name="status"
            label="Status"
            options={options.status}
          />
          <TextField
            multiline
            rowsMax="4"
            name="notes"
            label="Anotações"
            sm={12}
          />
          <SeriesFieldArray />
        </Grid>
      </Stepper>
    )
  )
}

const SeriesDetail = ({ name, children }) => (
  <Grid item sm={12}>
    <Grid container spacing={16}>
      <TextField sm={2} name={`${name}.isin`} label="ISIN" />
      <TextField sm={2} name={`${name}.cetip`} label="CETIP" />
      <DateField sm={2} name={`${name}.issueDate`} label="Emissão" />
      <DateField sm={2} name={`${name}.dueDate`} label="Vencimento" />
      <NumberField sm name={`${name}.value`} label="Valor Emitido" />
      <NumberField sm name={`${name}.amount`} label="Quantidade Emitida" />
      {children}
    </Grid>
  </Grid>
)

const SeriesList = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    <SeriesDetail name={`${fields.name}[0]`}>
      <IconButton
        color="secondary"
        aria-label="adicionar"
        style={{ marginTop: 10 }}
        onClick={() => fields.push()}>
        <Icon>add_circle</Icon>
      </IconButton>
    </SeriesDetail>
    {fields.map(
      (name, index) =>
        index > 0 && (
          <SeriesDetail name={name} key={index}>
            <IconButton
              color="secondary"
              aria-label="adicionar"
              style={{ marginTop: 10 }}
              onClick={() => fields.remove(index)}>
              <Icon>remove_circle</Icon>
            </IconButton>
          </SeriesDetail>
        )
    )}
  </React.Fragment>
)

const SeriesFieldArray = withProps({
  name: 'series',
  component: SeriesList
})(FieldArray)

FormTemplate.propTypes = {
  options: PropTypes.object.isRequired
}

FormTemplate.defaultProps = {
  options: {}
}

const FormView = compose(
  reduxForm({ form: 'bond' }),
  connect(
    (state, { autofill, options }) => {
      const selector = formValueSelector('bond')
      const { type } = selector(state, 'issuer', 'type')

      return { type }
    },
    (dispatch, ownProps) => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        })
    })
  )
)(FormTemplate)

export default FormView
