import React, { useContext } from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import { FirebaseContext } from '@ftrustee/ui'

import { isTokenExpired } from './helper/jwtHelper'

// Preconfigured HOC
const withApollo = BaseComponent => props => {
  const firebase = useContext(FirebaseContext)
  // Pass your GraphQL endpoint to uri
  const client = new ApolloClient({
    fetchOptions: {
      credentials: 'include'
    },
    request: async operation => {
      if (isTokenExpired(window.token)) {
        window.token = await firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
      }
      operation.setContext({
        headers: {
          authorization: window.token
        }
      })
    }
  })

  return (
    <ApolloProvider client={client}>
      <BaseComponent {...props} />
    </ApolloProvider>
  )
}

export default withApollo
