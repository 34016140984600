import { compose, branch, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { omit, omitBy, remove, isNull } from 'lodash'

import FormView from './FormView'
import { QUERY } from './GridContainer'

const companyFields = gql`
  fragment companyFields on Company {
    id
    cod
    cnpj
    name
    businessName
    group
    relationship
    industry
    website
    status
    held
    capital
    notes
    group
    phones
    address
  }
`
const withData = graphql(
  gql`
    query company($id: ID!) {
      company(id: $id) {
        ...companyFields
      }
    }
    ${companyFields}
  `,
  {
    options: ({ match }) => ({
      variables: {
        id: match.params.id
      }
    }),
    props: ({ data }) => {
      const props = {
        loading: data.loading
      }
      if (!data.loading) {
        const initialValues = omit(omitBy(data.company, isNull), '__typename')
        props.initialValues = initialValues
      }
      return props
    }
  }
)

const withSave = graphql(
  gql`
    mutation createCompany($data: CompanyInput!) {
      createCompany(data: $data) {
        ...companyFields
      }
    }
    ${companyFields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: data =>
        mutate({ variables: { data } }).then(() => history.push('/companies'))
    }),
    options: () => ({
      update: (cache, { data: { createCompany } }) => {
        const { companies } = cache.readQuery({ query: QUERY })
        cache.writeQuery({
          query: QUERY,
          data: { companies: companies.concat([createCompany]) }
        })
      }
    })
  }
)

const withUpade = graphql(
  gql`
    mutation updateCompany($id: ID!, $data: CompanyInput!) {
      updateCompany(id: $id, data: $data) {
        ...companyFields
      }
    }
    ${companyFields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: values => {
        // console.log(mutate, ownProps, values)
        const { id, ...data } = values
        return mutate({ variables: { id, data } }).then(() =>
          history.push('/companies')
        )
      }
    })
  }
)

const withDelete = graphql(
  gql`
    mutation deleteCompany($id: ID!) {
      deleteCompany(id: $id) {
        id
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { match, history } }) => ({
      onDelete: () =>
        mutate({ variables: { id: match.params.id } }).then(() =>
          history.replace('/companies')
        )
    }),
    options: ({ match }) => ({
      update: (proxy, { data: { deleteCompany } }) => {
        const data = proxy.readQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          }
        })
        remove(data.companies, deleteCompany)
        proxy.writeQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          },
          data
        })
      }
    })
  }
)

const withGroups = graphql(
  gql`
    query {
      companies {
        id
        name
      }
    }
  `,
  {
    props: ({ data }) =>
      !data.loading && {
        options: {
          groups: data.companies.map(company => ({
            value: company.id,
            label: company.name
          }))
        }
      }
  }
)

export default compose(
  withGroups,
  branch(
    ({ match }) => !!match.params.id,
    compose(
      withData,
      withUpade,
      withDelete
    ),
    withSave
  ),
  withHandlers({
    onCancel: ({ history }) => () => history.push('/companies')
  })
)(FormView)
